import { SVG } from '../svg'

type Props = {
  className?: string
}

export function IconLotto({ className }: Props) {
  // __RENDER
  return (
    <SVG className={className} width={32} height={18} viewBox='0 0 32 18'>
      <g clipPath='url(#clip0_140_50632)'>
        <path d='M27.8899 8.75977C27.3899 8.75977 26.9299 9.02977 26.6999 9.41977C26.6399 9.49977 26.6099 9.60977 26.6099 9.73977C26.6299 10.6298 27.0899 11.2798 27.9599 11.2798C28.6799 11.2798 29.1599 10.7598 29.1599 9.98977C29.1599 9.21977 28.6599 8.75977 27.8899 8.75977Z' />
        <path d='M28 0H4C1.79 0 0 1.79 0 4V14C0 16.21 1.79 18 4 18H28C30.21 18 32 16.21 32 14V4C32 1.79 30.21 0 28 0ZM4.26 11.76H3.46V7.05H3.44L2.37 7.55L2.21 7L3.55 6.37H4.26V11.76ZM10.42 11.76H6.5V11.31L7.15 10.75C8.72 9.44 9.43 8.75 9.44 7.93C9.44 7.38 9.14 6.88 8.22 6.88C7.66 6.88 7.2 7.13 6.92 7.34L6.66 6.83C7.08 6.51 7.69 6.28 8.4 6.28C9.72 6.28 10.28 7.07 10.28 7.85C10.28 8.85 9.46 9.65 8.16 10.74L7.67 11.14V11.16H10.43V11.76H10.42ZM12.92 11.85C12.23 11.85 11.63 11.66 11.32 11.49L11.55 10.93C11.79 11.06 12.33 11.27 12.91 11.27C13.98 11.27 14.31 10.67 14.3 10.23C14.3 9.48 13.52 9.15 12.71 9.15H12.25V8.6H12.71C13.31 8.6 14.08 8.33 14.08 7.69C14.08 7.26 13.77 6.88 13 6.88C12.51 6.88 12.04 7.07 11.77 7.24L11.55 6.71C11.87 6.5 12.49 6.3 13.16 6.3C14.37 6.3 14.92 6.93 14.92 7.58C14.92 8.13 14.54 8.61 13.79 8.85V8.87C14.54 9 15.16 9.5 15.16 10.25C15.16 11.11 14.4 11.87 12.92 11.87V11.85ZM20.41 10.29H19.55V11.76H18.77V10.29H15.92V9.81L18.66 6.37H19.56V9.72H20.42V10.29H20.41ZM22.55 11.85C21.88 11.85 21.31 11.68 21.02 11.52L21.23 10.97C21.48 11.1 21.99 11.27 22.54 11.27C23.32 11.27 24 10.82 24 10.1C24 9.4 23.46 8.91 22.23 8.91C21.88 8.91 21.61 8.94 21.38 8.97L21.78 6.39H24.72V7H22.38L22.14 8.38C22.28 8.36 22.41 8.35 22.64 8.35C23.11 8.35 23.58 8.44 23.96 8.64C24.44 8.88 24.84 9.34 24.84 10.02C24.84 11.07 23.89 11.86 22.55 11.86V11.85ZM27.96 11.85C26.65 11.85 25.79 10.96 25.79 9.56C25.79 8.5 26.22 7.66 26.83 7.13C27.34 6.69 28.02 6.42 28.79 6.34C29.04 6.31 29.24 6.3 29.4 6.3V6.9C29.23 6.9 29.01 6.9 28.78 6.93C27.48 7.12 26.79 7.96 26.65 8.84H26.68C26.97 8.5 27.48 8.22 28.16 8.22C29.25 8.22 30.01 8.91 30.01 9.96C30.01 10.95 29.25 11.86 27.97 11.86L27.96 11.85Z' />
        <path d='M18.25 7.86982L16.75 9.69982V9.71982H18.76V7.91982C18.76 7.63982 18.76 7.35982 18.79 7.06982H18.76C18.57 7.38982 18.42 7.61982 18.25 7.86982Z' />
      </g>
      <defs>
        <clipPath id='clip0_140_50632'>
          <rect width={32} height={18} />
        </clipPath>
      </defs>
    </SVG>
  )
}
