import { SVG } from '../svg'

type Props = {
  className?: string
}

export function IconCasino({ className }: Props) {
  // __RENDER
  return (
    <SVG className={className} width={28} height={32} viewBox='0 0 28 32'>
      <g clipPath='url(#clip0_140_50613)'>
        <path d='M24.6792 24.2297C27.9105 21.6333 28.4317 16.9096 25.8353 13.6783L23.6179 10.9161L15.4071 0.672714C14.6869 -0.227493 13.3129 -0.227493 12.5928 0.672714L4.37718 10.9209L2.15983 13.6831C-0.431824 16.9143 0.0893486 21.6381 3.32062 24.2345C4.7041 25.3431 6.36237 25.8833 8.00644 25.8833C9.22408 25.8833 10.4275 25.5753 11.522 24.9973L10.7355 26.357L8.33809 30.5122C7.95432 31.1755 8.43285 32.0047 9.20039 32.0047H18.7994C19.567 32.0047 20.0455 31.1755 19.6618 30.5122L17.2644 26.357L16.4826 24.9973C17.5723 25.5753 18.7758 25.8785 19.9934 25.8785C21.6327 25.8833 23.291 25.3431 24.6792 24.2297Z' />
      </g>
      <defs>
        <clipPath id='clip0_140_50613'>
          <rect width={26.9778} height={32} transform='translate(0.51123)' />
        </clipPath>
      </defs>
    </SVG>
  )
}
